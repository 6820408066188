import React, { useRef, useState } from "react";
import { Button } from "@chakra-ui/button";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
} from "@chakra-ui/modal";
import { Box } from "@chakra-ui/react";
import { isDev } from "../../config";
import { PRODUCTION_MODE } from "../../constants";
import { StorageService } from "../../service/Storage";
import { isProdMode } from "@/utils";

export default function SwitchToProductionMode() {
  const [isOpen, setIsOpen] = useState(false);
  const cancelRef = useRef(null);
  if (!isDev) return null;

  function onClose() {
    setIsOpen(false);
  }

  function onProdMode() {
    StorageService.set(PRODUCTION_MODE, "true");
    StorageService.set("__TWEAK_API_ENDPOINT__", "https://api.chillar.co.in");

    window.location.reload();
  }
  function onNormalMode() {
    StorageService.set(PRODUCTION_MODE, undefined);
    StorageService.set("__TWEAK_API_ENDPOINT__", undefined);
    window.location.reload();
  }

  return (
    <Box
      my={6}
      style={{ display: "flex", justifyContent: "center", padding: "0.3rem" }}
    >
      <Button
        colorScheme={isProdMode ? "green" : "red"}
        onClick={() => setIsOpen(true)}
        size="md"
      >
        Switch to {isProdMode ? "normal" : "production"} mode
      </Button>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold" fill="red">
              Switch to {isProdMode ? "normal" : "production"} mode ?
            </AlertDialogHeader>

            <AlertDialogBody>
              {isProdMode
                ? " All the API calls will point to dev mode now."
                : "Are you sure? All the API calls will point to prod-api."}
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose} size="md">
                Cancel
              </Button>
              &nbsp; &nbsp;
              <Button
                colorScheme="red"
                onClick={isProdMode ? onNormalMode : onProdMode}
                size="md"
              >
                Confirm
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
}
